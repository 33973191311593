import React from "react";
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Brighter Days</title>
        <description>
          We leverage the power of today's technology to create tomorrow's
          Brighter Days
        </description>
        <link rel="canonical" href="https://brighterdays.ventures/" />
      </Helmet>
      <div className=" h-screen-ios flex h-screen min-h-[400px] flex-col justify-end overflow-hidden bg-[#FCB43C] text-[#FFFFFF] md:h-screen md:max-h-screen">
        <div className="p-8 md:p-[8rem]">
          <h1 className="pt-8 text-2xl md:pt-[4rem] md:text-[4rem] md:leading-none">
            Brighter Days.
          </h1>
        </div>
      </div>
    </>
  );
};

export default IndexPage;
